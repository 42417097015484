<template>
  <div class="row justify-content-center">
    <template v-for="item in nav">
      <div class="col-auto" :key="item.name" v-if="!item.hidden">
        <router-link :to="{ name: item.name, params: item.params }">
          <h5>
            <b>
              <font-awesome-icon :icon="['fa', item.icon]" class="icon" />
              {{ item.text }}
            </b>
          </h5>
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "NavigationSlide",
  props: ["nav"],
  computed: {
    routerAction() {
      console.log(this.$route);
      return this.$route.meta.action  
    },
    routerResource() {
      console.log(this.$router.meta.resource);
      return this.$route.meta.resource
    },
  },
};
</script>

<style>
</style>